<template>
  <div>
    <div class="container">
      <sub-header
        :pathList="['Home', 'Service Admin', 'User Management']"
        :title="{
          mainTitle: 'Service Admin',
          smallTitle: 'User Management',
        }"
      />

      <!-- contents -->
      <div id="contents" class="contents">
        <div class="title-sort-box">
          <h3>User List</h3>
          <div class="sort-side">
            <label class="search-label ic-bullet">User ID</label>
            <span
              class="search-icon-wrap"
              style="width: 120px; border: 1px solid #ddd;"
            >
              <input
                type="text"
                class="isf"
                v-model="userId"
                maxlength="20"
                dataType="LITERAL"
                v-on:keyup.enter="onSearch"
              />
            </span>
            <label class="search-label ic-bullet">User Name</label>
            <span
              class="search-icon-wrap"
              style="width: 120px; border: 1px solid #ddd;"
            >
              <input
                type="text"
                class="isf"
                maxlength="20"
                dataType="LITERAL"
                v-model="userNm"
                v-on:keyup.enter="onSearch"
              />
            </span>
            <label class="search-label ic-bullet">Registration Date</label>
            <span class="daterange-wrap">
              <date-picker
                v-model="rangeDate"
                mode="date"
                :columns="2"
                :step="1"
                is-range
              >
                <template #default="{ inputValue, togglePopover}">
                  <input
                    type="text"
                    class="isf"
                    style="width: 225px;"
                    dataType="LITERAL"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    v-on:keyup.enter="onSearch"
                    autocomplete="off"
                    @click="togglePopover()"
                  />
                  <label
                    for="findDateDaterangepicker"
                    @click="togglePopover()"
                    class="ic-calendar"
                  ></label>
                </template>
              </date-picker>
            </span>
            <button
              type="submit"
              class="bt-md bt-default"
              @click="onSearch"
              style="margin-left: 10px; vertical-align: top;"
            >
              <span>Search</span>
            </button>
          </div>
        </div>
        <!--/title-sort-box-->
        <list-form
          :fetchApi="fetchUserList"
          :columns="columns"
          @detail="openUserPopup"
          @resource="openResourcePopup"
          ref="userList"
        />
      </div>
      <!-- /contents -->
    </div>
    <user-popup
      v-if="userPopup"
      @close="userPopup = false"
      :userId="selectUserId"
    />
    <fhir-resource-popup
      v-if="resourcePopup"
      @close="resourcePopup = false"
      :userId="selectUserId"
      :patientId="selectFhirResrce"
    />
  </div>
</template>
<script>
import ListForm from '@/components/common/ListForm.vue';
import { fetchUserList } from '@/api/serviceApi.js';
import CommonMixins from '@/mixins/CommonMixins.js';
import UserPopup from './UserPopup.vue';
import FhirResourcePopup from './FhirResourcePopup.vue';

export default {
  components: { ListForm, UserPopup, FhirResourcePopup },
  mixins: [CommonMixins],
  data() {
    return {
      columns: [
        {
          label: 'User ID',
          field: 'userId',
          width: '18%',
        },
        {
          label: 'User Name',
          field: 'userNm',
          width: '18%',
        },
        {
          label: 'Service Name (Service ID)',
          field: 'srvc',
          sortable: false,
          width: '26%',
        },
        {
          label: 'Reg. Date',
          field: 'srbdeDt',
          formatFn: val => CommonMixins.methods.common_getDateString(val),
          width: '18%',
        },
        {
          label: 'FHIR Resource',
          field: 'fhirResrceBtn',
          html: true,
          sortable: false,
          width: '16%',
        },
      ],
      userId: '',
      userNm: '',
      rangeDate: {
        start: '',
        end: '',
      },
      userPopup: false,
      selectUserId: '',
      selectFhirResrce: '',
      resourcePopup: false,
    };
  },
  methods: {
    // 회원 목록
    fetchUserList(params) {
      return fetchUserList(params);
    },
    // 회원 상세조회 팝업
    openUserPopup(row) {
      this.selectUserId = row.userId;
      this.userPopup = true;
    },
    // 리소스 보기 팝업
    openResourcePopup({ userId, fhirResrce }) {
      this.selectUserId = userId;
      this.selectFhirResrce = fhirResrce;
      this.resourcePopup = true;
    },
    //검색
    onSearch() {
      let params = {
        page: 1,
        userId: this.userId,
        userNm: this.userNm,
        startDateString: this.dateFormat(this.rangeDate.start),
        endDateString: this.dateFormat(this.rangeDate.end),
      };

      this.$refs.userList.onSearch(params);
    },
    dateFormat(date) {
      if (!date) return '';
      let newDate = new Date(date);
      let year = newDate.getFullYear(); //yyyy
      let month = 1 + newDate.getMonth(); //M
      month = month >= 10 ? month : '0' + month; //month 두자리로 저장
      let day = newDate.getDate(); //d
      day = day >= 10 ? day : '0' + day; //day 두자리로 저장
      return year + '-' + month + '-' + day;
    },
  },
};
</script>
<style scoped>
@import url('./user.css');
</style>
