<template>
  <div class="alert-mask " :class="active ? 'active' : ''">
    <div
      class="popup-area large scroll-pop "
      :class="active ? 'active' : ''"
      style="height: 85%;"
      id="popup_detail"
    >
      <div class="pop-header">
        <h1 class="pop-title">User Detail</h1>
        <button class="pop-x pop_close_btn" @click="$emit('close')">
          Close
        </button>
      </div>
      <div class="pop-contents">
        <div class="noPlugin-scroll">
          <article class="board-view">
            <div class="table-horizon st01">
              <table class="form-md">
                <colgroup>
                  <col style="width:15%" />
                  <col style="width:32%" />
                  <col style="width:21%" />
                  <col style="width:32%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star table-label">User ID</label>
                    </th>
                    <td>
                      <span class="isf">{{ userId || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">User name</label>
                    </th>
                    <td>
                      <span class="isf">{{ userNm || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Service name</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        `${srvcNm}(${srvcId})` || '-'
                      }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Birthdate</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getDateString(userBrthdy).split(' ')[0] || '-'
                      }}</span>
                    </td>
                    <th></th>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Anniversary date</label
                      >
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getDateString(mrrgAnnvrsry).split(' ')[0] || '-'
                      }}</span>
                    </td>
                    <th><label class="ic-star table-label">Gender</label></th>
                    <td>
                      <span class="isf">{{ userSexdstn || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th><label class="ic-star table-label">E-mail</label></th>
                    <td>
                      <span class="isf">{{ userEmail || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Cellphone</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getTelephoneNoText(userMoblphon) || '-'
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Telephone</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getTelephoneNoText(userCbleTlphon) || '-'
                      }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Zip code</label>
                    </th>
                    <td>
                      <span class="isf">{{ userZip || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Address 1</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">{{ userAdres || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Address 2</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">{{ userDetailAdres || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Lost ID Security Question</label
                      >
                    </th>
                    <td colspan="3">
                      <span class="isf">{{
                        idLosQestn ? `${idLosQestn}/${idLosAnswer}` : '-'
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Patient Resource ID</label
                      >
                    </th>
                    <td>
                      <span class="isf">{{ fhirResrce || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Registration</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getDateString(srbdeDt) || ''
                      }}</span>
                    </td>
                  </tr>
                  <!-- <tr style="border-top: 1px solid #ddd;"><th></th><td colspan="3"></td></tr> -->
                </tbody>
              </table>
            </div>
          </article>
          <!------------// 게시판 내용 end ------------------>
          <div class="btns-bottom">
            <button
              type="button"
              class="bt-md bt-black pop_close_btn"
              @click="$emit('close')"
            >
              Close
            </button>
          </div>
        </div>
        <!--noPlugin-scroll-->
      </div>
      <!--/pop-contents-->
    </div>
  </div>
</template>
<script>
import { fetchUserDetail } from '@/api/serviceApi.js';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  mixins: [CommonMixins],
  props: {
    userId: {
      type: String,
    },
  },

  data() {
    return {
      userNm: '',
      srvcNm: '',
      srvcId: '',
      userBrthdy: '',
      mrrgAnnvrsry: '',
      userSexdstn: '',
      userEmail: '',
      userMoblphon: '',
      userCbleTlphon: '',
      userZip: '',
      userAdres: '',
      userDetailAdres: '',
      idLosQestn: '',
      idLosAnswer: '',
      fhirResrce: '',
      srbdeDt: '',
      active: false,
    };
  },
  created() {
    this.fetchUserDetail();
  },
  methods: {
    // 회원 상세 정보 조회
    async fetchUserDetail() {
      const { data } = await fetchUserDetail({ userId: this.userId });
      this.active = true;
      Object.assign(this.$data, data);
    },
  },
};
</script>
<style></style>
