<template>
  <div class="alert-mask " :class="active ? 'active' : ''">
    <div
      class="popup-area xlarge scroll-pop"
      style="width: 1420px; "
      :class="active ? 'active' : ''"
    >
      <div class="pop-header">
        <h1 class="pop-title">FHIR Resource Detail</h1>
        <button class="pop-x pop_close_btn" @click="$emit('close')">
          Close
        </button>
      </div>
      <div class="pop-contents">
        <div class="noPlugin-scroll" style="overflow-x: hidden;">
          <article class="board-view">
            <div class="table-horizon st01">
              <table class="form-md">
                <colgroup>
                  <col style="width:19%" />
                  <col style="width:28%" />
                  <col style="width:25%" />
                  <col style="width:28%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star table-label">User ID</label>
                    </th>
                    <td>
                      <span class="isf">{{ userId || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label"
                        >FHIR Resource ID</label
                      >
                    </th>
                    <td>
                      <span class="isf">{{ patientId || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Resource Contents</label
                      >
                    </th>
                    <td>
                      <label class="ic-bullet" style="margin-left: -20px;"
                        >Version</label
                      >
                      <select
                        class="isf term-sel"
                        style="width: unset; margin-left: 10px;"
                        v-model="resourceData.selectVersion"
                        v-html="appendOptions(resourceData.versionId)"
                        @change="
                          fetchUserResrouce({
                            userId,
                            patientId,
                            historyId: resourceData.selectVersion,
                          })
                        "
                      >
                      </select>
                    </td>
                    <th></th>
                    <td></td>
                  </tr>
                  <tr>
                    <th></th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ resourceData.jsonData }}</span>
                      </span>
                    </td>
                  </tr>
                  <!-- <tr style="border-top: 1px solid #000;"><th></th><td colspan="3"></td></tr> -->
                </tbody>
              </table>
            </div>
            <div class="table-horizon st01" style="border-top: 1px solid #ddd;">
              <table class="form-md">
                <colgroup>
                  <col style="width:19%" />
                  <col style="width:28%" />
                  <col style="width:25%" />
                  <col style="width:28%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Related Resource List</label
                      >
                    </th>
                    <td colspan="3">
                      <label class="ic-bullet" style="margin-left: -20px;"
                        >Resource Type</label
                      >
                      <select
                        class="isf term-sel"
                        style="width: unset; margin-left: 10px;"
                        v-model="resourceType"
                        @change="setSearchFhirCont"
                      >
                        <option value="">Resource Type</option>
                        <option
                          v-for="index in filterResoucreType()"
                          :key="index.value"
                          :value="index.value"
                          >{{ index.text }}</option
                        >
                      </select>
                      <label class="ic-bullet">options</label>
                      <input
                        class="isf term-sel"
                        style="width: unset; margin-left: 10px; width: 35%;"
                        v-model="searchFhirCont"
                        v-on:keyup.enter="onSearch"
                      />
                      <button
                        type="submit"
                        class="bt-md bt-default"
                        @click="openFhirContentPopup"
                        style="margin-left: 10px; vertical-align: top;"
                      >
                        <span>Edit options</span>
                      </button>
                      <button
                        type="submit"
                        class="bt-md bt-default"
                        @click="onSearch"
                        style="margin-left: 10px; vertical-align: top;"
                      >
                        <span>Search</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <list-form
              :fetchApi="fetchUserResourceList"
              :columns="columns"
              :initLoad="listInitLoad"
              @detail="setRelatedResource"
              ref="resourceList"
            />
            <div class="table-horizon st01" style="border-top: unset;">
              <table class="form-md">
                <colgroup>
                  <col style="width:19%" />
                  <col style="width:28%" />
                  <col style="width:25%" />
                  <col style="width:28%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Related Resource List</label
                      >
                    </th>
                    <td colspan="3">
                      <label class="ic-bullet" style="margin-left: -20px;"
                        >ID</label
                      >
                      <span
                        class="isf"
                        style="width: auto; margin-left: 10px;"
                        >{{ relatedResourceData.id || '-' }}</span
                      >
                      <label class="ic-bullet">Version</label>
                      <select
                        class="isf term-sel"
                        style="width: unset; margin-left: 10px;"
                        v-model="relatedResourceData.selectHistroyId"
                        v-html="appendOptions(relatedResourceData.historyId)"
                        @change="
                          fetchUserResrouce(
                            {
                              patientId: relatedResourceData.id,
                              historyId: relatedResourceData.selectHistroyId,
                            },
                            'related',
                          )
                        "
                      ></select>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{
                          relatedResourceData.jsonData
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <!------------// 게시판 내용 end ------------------>
          <div class="btns-bottom">
            <button
              type="button"
              class="bt-md bt-black pop_close_btn"
              @click="$emit('close')"
            >
              Close
            </button>
          </div>
        </div>
        <!--noPlugin-scroll-->
      </div>
      <!--/pop-contents-->
      <fhir-content-popup
        v-if="fhirContntPopup"
        :resourceType="resourceType"
        :searchFhirCont="searchFhirCont"
        @update="updateSearchFhirCont"
        @close="fhirContntPopup = false"
      />
    </div>
  </div>
</template>
<script>
import { fetchUserResrouce, fetchUserResourceList } from '@/api/serviceApi.js';
import ListForm from '@/components/common/ListForm.vue';
import resourceSearch from './resourceSearch';
import FhirContentPopup from './FhirContentPopup.vue';
export default {
  mixins: [resourceSearch],
  components: { ListForm, FhirContentPopup },
  props: {
    userId: {
      type: String,
    },
    patientId: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
      resourceData: {
        jsonData: '',
        versionId: '',
        selectVersion: '',
      },
      resourceType: '',
      searchFhirCont: '',
      relatedResourceData: {
        id: '',
        jsonData: '',
        historyId: '',
        selectHistroyId: '',
      },
      columns: [
        {
          label: 'Resource Type',
          field: 'resourceType',
          sortable: false,
          width: '20%',
        },
        {
          label: 'Resource URL',
          field: 'fullUrl',
          sortable: false,
          width: '76%',
        },
      ],
      listInitLoad: false,
      fhirContntPopup: false,
    };
  },
  created() {
    this.fetchUserResrouce({ userId: this.userId, patientId: this.patientId });
  },
  methods: {
    // 리소스 정보 가져오기
    async fetchUserResrouce(params, type) {
      const {
        data: { jsonData, versionId, patientId },
      } = await fetchUserResrouce(params).catch(() => this.$emit('close'));
      if (type === 'related') {
        this.relatedResourceData.id = patientId;
        this.relatedResourceData.jsonData = jsonData;
        this.relatedResourceData.selectHistroyId = versionId;
      } else {
        this.resourceData.jsonData = jsonData;
        this.resourceData.versionId = this.resourceData.versionId || versionId;
        this.resourceData.selectVersion = versionId;
      }

      this.active = true;
    },
    // 버전 세팅
    appendOptions(versionId) {
      let options = '';
      for (let i = 1; i <= versionId; i++) {
        options += `<option value=${i}>${i}</option>`;
      }
      return options;
    },
    //관련 리소스 목록 가져오기
    fetchUserResourceList(params) {
      return fetchUserResourceList(params);
    },
    // 리소스 타입 목록
    filterResoucreType() {
      return resourceSearch.data().FHIR_RESOURCES.filter(val => val.patient);
    },
    // 리소스 타입 수정시 검색 내용 세팅
    setSearchFhirCont() {
      const { patient } = resourceSearch
        .data()
        .FHIR_RESOURCES.find(val => val.value === this.resourceType);
      if (patient) {
        this.searchFhirCont = `?${patient}=${this.patientId}`;
      } else {
        this.searchFhirCont = '';
      }
    },
    // 검색
    onSearch() {
      let params = {
        page: 1,
        resourceType: this.resourceType,
        searchUrl: this.searchFhirCont,
      };

      this.$refs.resourceList.onSearch(params);
    },
    // 리스트 선택시 관련 리소스 내용 세팅
    setRelatedResource({ resourceId, historyId, jsonData }) {
      this.relatedResourceData.id = resourceId;
      this.relatedResourceData.jsonData = jsonData;
      this.relatedResourceData.historyId = historyId;
      this.relatedResourceData.selectHistroyId = historyId;
    },
    // 검색 내용 수정
    updateSearchFhirCont(val) {
      this.fhirContntPopup = false;
      this.searchFhirCont = val;
    },
    // 검색 내용 편집 팝업
    openFhirContentPopup() {
      if (!this.patientId) {
        this.$dialogs.alert('No Resource ID found.');
        return;
      }
      if (this.resourceType == '') {
        this.$dialogs.alert('Please select Resource Type<br>');
        return;
      }
      this.fhirContntPopup = true;
    },
  },
};
</script>
<style></style>
