<template>
  <div class="alert-mask active">
    <div
      class="popup-area medium scroll-pop active"
      style="height: auto; overflow-y: auto; z-index: 105;"
    >
      <div class="pop-header">
        <h1 class="pop-title">Edit Search</h1>
        <button class="pop-x" @click="$emit('close')">Close</button>
      </div>
      <div class="pop-contents">
        <div class="noPlugin-scroll">
          <article class="board-view">
            <div
              class="table-horizon st01"
              style="padding-bottom: unset; border-bottom: unset;"
            >
              <label class="ic-star table-label" style="font-size: 15px;"
                >Search Options</label
              >
              <table
                class="form-md"
                id="popup_optsGrid"
                style="max-height:200px; overflow: auto;  margin-top: 10px;"
              >
                <colgroup>
                  <col width="40%" />
                  <col width="*" />
                  <col width="5%" />
                </colgroup>
                <tbody id="pop_opts_tbody">
                  <tr
                    v-for="index in optsIdx"
                    :key="index"
                    :id="`tr_opts_${index}`"
                  >
                    <td>
                      <input
                        :list="`select_opts_${index}`"
                        :id="`select_opts_${index}_input`"
                        class="isf pop-input"
                        autocomplete="off"
                      />
                      <datalist :id="`select_opts_${index}`">
                        <option
                          v-for="item in SEARCH_RESOURCES[resourceType]"
                          :key="item.key"
                          :value="item.key"
                          >{{ item.key }}</option
                        >
                      </datalist>
                    </td>
                    <td>
                      <input
                        type="text"
                        :id="`input_opts_${index}`"
                        class="isf term-sel pop-input"
                        @blur="setPreviewFhirCont"
                        autocomplete="off"
                      />
                    </td>
                    <td style="vertical-align: middle;">
                      <input
                        type="button"
                        class="bt-line22 bt-num-plus btnAddRow"
                        :class="
                          index == optsIdx ? 'bt-num-plus' : 'bt-num-minus'
                        "
                        :id="`opts_${index}`"
                        :value="index == optsIdx ? '+' : '-'"
                        @click="appendRow('opts', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="table-horizon st01"
              style="padding: unset; border-top: unset;"
            >
              <label class="ic-star table-label" style="font-size: 15px;"
                >Search Sorting Options</label
              >
              <table
                class="form-md"
                id="popup_sortsGrid"
                style="max-height:200px; overflow: auto; margin-top: 10px;"
              >
                <colgroup>
                  <col width="40%" />
                  <col width="*" />
                  <col width="5%" />
                </colgroup>

                <tbody id="pop_sorts_tbody">
                  <tr
                    v-for="index in sortsIdx"
                    :key="index"
                    :id="`tr_sorts_${index}`"
                  >
                    <td>
                      <input
                        :list="`select_sorts_${index}`"
                        :id="`select_sorts_${index}_input`"
                        class="isf pop-input"
                        autocomplete="off"
                      />
                      <datalist :id="`select_sorts_${index}`">
                        <option
                          v-for="item in SEARCH_RESULT_PARAMETERS"
                          :key="item.key"
                          :value="item.key"
                          >{{ item.key }}</option
                        >
                      </datalist>
                    </td>
                    <td>
                      <input
                        type="text"
                        :id="`input_sorts_${index}`"
                        class="isf term-sel pop-input"
                        @blur="setPreviewFhirCont"
                        autocomplete="off"
                      />
                    </td>
                    <td style="vertical-align: middle;">
                      <input
                        type="button"
                        class="bt-line22 btnAddRow"
                        :class="
                          index == sortsIdx ? 'bt-num-plus' : 'bt-num-minus'
                        "
                        :id="`sorts_${index}`"
                        :value="index == sortsIdx ? '+' : '-'"
                        @click="appendRow('sorts', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-horizon st01">
              <table class="form-md">
                <colgroup>
                  <col style="width:19%" />
                  <col style="width:28%" />
                  <col style="width:25%" />
                  <col style="width:28%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th><label class="ic-star table-label">Preview</label></th>
                    <td colspan="3">
                      <textarea
                        class="isf"
                        id="popup_search_content"
                        disabled="disabled"
                        v-model="previewFhirCont"
                        style="color: #5a5a5a; height: 100px;"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <!------------// 게시판 내용 end ------------------>
          <div class="btns-bottom">
            <button
              type="button"
              class="bt-md bt-red"
              @click="$emit('update', previewFhirCont)"
            >
              OK
            </button>
            <button
              type="button"
              class="bt-md bt-black"
              @click="$emit('close')"
            >
              Close
            </button>
          </div>
        </div>
        <!--noPlugin-scroll-->
      </div>
      <!--/pop-contents-->
    </div>
  </div>
</template>
<script>
import resourceSearch from './resourceSearch';

export default {
  mixins: [resourceSearch],
  props: {
    resourceType: {
      type: String,
    },
    searchFhirCont: {
      type: String,
    },
  },
  data() {
    return {
      optsIdx: 1,
      sortsIdx: 1,
      previewFhirCont: this.searchFhirCont,
    };
  },
  async mounted() {
    // 초기값이 있을 경우 세팅
    if (this.searchFhirCont.indexOf('&') > -1) {
      let params = this.searchFhirCont.split('&');
      let event = { target: { value: '+' } };
      let type, idx;
      for (let i = 1; i < params.length; i++) {
        if (params[i].indexOf('_') > -1) {
          type = 'sorts';
          await this.appendRow(type, event);
        } else {
          type = 'opts';
          await this.appendRow(type, event);
        }
        idx = type === 'opts' ? this.optsIdx - 1 : this.sortsIdx - 1;
        document.getElementById(`select_${type}_${idx}_input`).value = params[
          i
        ].split('=')[0];
        document.getElementById(`input_${type}_${idx}`).value = params[i].split(
          '=',
        )[1];
      }
      this.setPreviewFhirCont();
    }
  },
  methods: {
    // row 추가 / 삭제
    appendRow(type, event) {
      const {
        target: { value, id },
      } = event;
      if (value === '+') {
        type === 'opts' ? this.optsIdx++ : this.sortsIdx++;
      } else {
        document.getElementById(`tr_${id}`).remove();
      }
      this.setPreviewFhirCont();
    },
    // 미리보기 세팅
    setPreviewFhirCont() {
      let optsRows = document.getElementById('pop_opts_tbody').children;
      let content = this.searchFhirCont.split('&')[0];
      content += this.appendPreview(optsRows);
      let sortsRows = document.getElementById('pop_sorts_tbody').children;
      content += this.appendPreview(sortsRows);
      this.previewFhirCont = content;
    },
    appendPreview(rows) {
      let content = '';
      for (let i in rows) {
        if (rows[i].cells) {
          let cells_select = rows[i].cells[0].children[0].value;
          let cells_input = rows[i].cells[1].children[0].value;

          if (cells_select && cells_input) {
            content += '&' + cells_select + '=' + cells_input;
          }
        }
      }
      return content;
    },
  },
};
</script>
<style scoped>
.select-editable {
  position: relative;
  background-color: white;
  border: solid grey 1px;
  width: 120px;
  height: 18px;
}
.select-editable select {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 14px;
  border: none;
  width: 120px;
  margin: 0;
}
.select-editable input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  padding: 1px;
  font-size: 12px;
  border: none;
}
.select-editable select:focus,
.select-editable input:focus {
  outline: none;
}
</style>
